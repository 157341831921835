import React from "react";
import "./Fun.css";

function Fun() {
  return (
    <div className="fun">
      <h1>Moro. Og mye av det. </h1>
      <p>
        Jeg gjør mye gøy. Det siste eventyret var en backpacking-runde i
        Sørøst-Asia våren 2023. Bilder kommer, stay tuned!{" "}
      </p>
    </div>
  );
}

export default Fun;
