import React from "react";
import "./About.css";
import Strand from "../Images/Strand.jpg";
import kontorstol from "../Images/IMG_045.jpg";

function About() {
  return (
    <div>
      <div className="about">
        <h1 className="aboutHeading">
          En eventyrer som elsker å lage gode historier.
        </h1>
      </div>
      <div className="aboutTextSection">
        <div className="contentContainer">
          <div className="textContent">
            <h2>Om Just</h2>
            <p className="aboutHeaderText">
              Jeg er en engasjert student på Datateknologi på NTNU i Trondheim.
              Teknologi er utrolig spennende og jeg synes det er spesielt
              morsomt å kunne se resultatet av å utvikle noe. Jeg tar mulighetene jeg kan -
              på alle mulige måter! Med det følger masse feil og vegger å møte,
              men dermed like mye lærdom og utvikling.
            </p>
            <p>
              Jeg elsker moro og har fomo til de grader. Utenfor arbeid finner
              du meg som oftest blant folk med et smil om munnen! “Du angrer mer
              på noe du ikke gjør, enn noe du gjør” tenker jeg ofte. Det får meg
              til å gjøre mye.
            </p>
            <p>
              Holdningen min om at ting ordner seg til slutt gjør at jeg ser
              positivt på det meste, og har det gøy mens jeg gjør det. Det
              reflekteres gjennom det meste jeg gjør, være seg reise,
              prosjekter, frivillig arbeid eller jobb.
            </p>
          </div>
          <img src={kontorstol} alt="kontorstol" className="kontorstolImage" />
        </div>
      </div>
    </div>
  );
}

export default About;
