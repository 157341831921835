import React from "react";
import "./Home.css";
import myPhoto from "../Images/IMG_6176.png";
import emailLogo from "../Images/emailLogo.png"
import linkedInLogo from "../Images/Linkedin.png"
import instagramLogo from "../Images/InstagramLogoWhite.png"


function Home() {
  return (
    <div className="home">
      <section className="intro">
        <div className="intro-text">
          <h1>Hei! Jeg er Just 👋 </h1>
          <p>
            Begeistringsjeger og datastudent. Jobber for tiden som
            forretningsutvikler hos EYD ved siden av studiene. Dette er et av
            mine mange prosjekter - ta en titt!
          </p>
          <p>
            <a href="https://www.linkedin.com/in/justbroch" target="_blank" rel="noopener norefferer">
              <img src={linkedInLogo} alt="linkedInLogo" className="linkedInLogo"/>
            </a>
{/*             <a href="mailto:just1998@live.no">
              <img src={emailLogo} alt="emailLogo" className="emailLogo"/> 
            </a> */}
            <a href="https://www.instagram.com/justbroch/">
              <img src={instagramLogo} alt="instagramLogo" className="instagramLogo"/>
            </a>
          </p>
        </div>
        <div className="intro-photo">
          <img src={myPhoto} alt="Just Broch" />
        </div>
      </section>
{/*       <section>
        <div className="intro-photo">
          <img src={myPhoto} alt="Just Broch" />
        </div>
      </section> */}
    </div>
  );
}

export default Home;
