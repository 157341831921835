import React from "react";
import "./Portfolio.css";
import kontorImage from "../Images/kontor.jpg";
import NTNU from "../Images/NTNU.jpg";

function Portfolio() {
  return (
    <div className="portfolio">
      <section className="intro">
        <div className="intro-text">
          <h1>Erfaringer og prosjekter </h1>
          <p>
            For tiden går jeg på sivilingeniørstudiet Datateknologi ved NTNU.
            Jeg har erfaring som forretningsutvikler, fra jobb i Forsvaret og
            fra en rekke frivillige verv som student. Det er i skjæringspunktet
            mellom kreative og tekniske prosjekter du finner min
            spisskompetanse.
          </p>
        </div>
        <div>
          <img src={NTNU} alt="Bilde foran NTNU" className="NTNU" />
        </div>
      </section>
      <div className="horizontal-line"></div>
      <section className="secondText">
        <div className="contentContainer">
          <div className="textContent">
            <h2>Forretningsutvikler hos EYD</h2>
            <p>
              Sommeren 2023 ønsket jeg å utfordre meg selv og tilegne meg mer
              kunnskap i en teknologisk bedrift. Min rolle som
              forretningsutvikler hos EYD, et IT-selskap som spesialiserer seg
              på personvern og sikkerhet, ga meg verdifull erfaring med
              produktutvikling og forretningsforståelse. Gjennom sommeren
              utviklet og designet jeg nettsiden til EYD. I tillegg utviklet jeg
              kampanjer, markedsføring og bestemte innhold og utforming.
              Erfaringen styrket ferdighetene mine i samarbeid i varierte team,
              vurdering av IT-løsninger og de forskjellige delene av å utvikle
              en IT-plattform som selskap.
            </p>
          </div>
          <div className="kontorImage">
            <img src={kontorImage} alt="Kontorbilde" />
          </div>
        </div>
      </section>
      <div className="horizontal-line"></div>
      <section className="thirdText">
        <div className="contentContainer2">
          <div className="textContent2">
            <h2>Stay tuned! </h2>
            <p>Mer kommer, nettsiden er under arbeid.</p>
          </div>
          {/*           <div className="kontorImage">
            <img src={kontorImage} alt="Kontorbilde" />
          </div> */}
        </div>
      </section>
      <div className="horizontal-line"></div>
    </div>
  );
}

export default Portfolio;
