import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import JBLogoWhite from "../Images/JBLogoWhite.png";

const Header = () => {
  return (
    <div className="header-container">
      <div className="logo-and-title-container">
      <Link to="/" style={{ textDecoration: "none", display: "flex", alignItems: "center", color: "white" }}>
        <img src={JBLogoWhite} alt="JB Logo" className="header-logo" />
        <h1 className="header-title">Just Broch</h1>
      </Link>
      </div>
      <nav>
        <Link to="/">Hjem</Link>
        <Link to="/about">Om meg</Link>
        {/* <Link to="/fun">Fun</Link> */}
        <Link to="/portfolio">Arbeid</Link>
        <Link to="/contact">Kontakt</Link>
      </nav>
    </div>
  );
};

export default Header;
