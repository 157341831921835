import React from "react";
import "./Contact.css";
import solImage from "../Images/Sol.jpg";
import emailLogo from "../Images/emailLogo.png";
import linkedInLogo from "../Images/Linkedin.png";
import instagramLogo from "../Images/InstagramLogoWhite.png";

function Contact() {
  return (
    <div className="contact">
      <section className="section1">
        <div className="contactText1">
          <h1>La oss ta en prat! </h1>
          <p>
            Snakke engasjement, arbeid eller vil du dele en god idé? Jeg er
            alltid åpen for en kaffe.
          </p>
          <p>
            <a
              href="https://www.linkedin.com/in/justbroch"
              target="_blank"
              rel="noopener norefferer"
            >
              <img
                src={linkedInLogo}
                alt="linkedInLogo"
                className="linkedInLogo"
              />
            </a>
{/*             <a href="mailto:just1998@live.no">
              <img src={emailLogo} alt="emailLogo" className="emailLogo" />
            </a> */}
            <a href="https://www.instagram.com/justbroch/">
              <img
                src={instagramLogo}
                alt="instagramLogo"
                className="instagramLogo"
              />
            </a>
          </p>
        </div>
        <div>
          <img src={solImage} alt="Sol" className="sol-image" />{" "}
        </div>
      </section>
    </div>
  );
}

export default Contact;
